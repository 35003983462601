import React from "react";

import {
  Layout,
  SEO,
  BannerStandard,
  ContainerBackgroundWhite,
  ContainerContentFixed,
  SliderClients,
  SliderTechStack,
} from "../components";
import { ExternalLink, InternalLinkTypes } from "../components/links";
import { HeadingSection } from "../components/headings";
import { Colors } from "../components/constants";
import { ContainerBackground } from "../components/containers";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col, Card, Icon } from "antd";
import Img from "gatsby-image";
import { SliderPartners } from "../components/sliders/siderPartners";

const maxWidth = "760px";
const About = () => {
  const data = useStaticQuery(graphql`
    query {
      ImgBrett: file(relativePath: { eq: "team/brett.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgAndrew: file(relativePath: { eq: "team/andrew.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout pageLink={InternalLinkTypes.About}>
      <SEO title={"About Us"} />
      <BannerStandard
        title={"About Us"}
        subTitle={"Proudly Australian owned and operated"}
      />
      <ContainerBackgroundWhite style={{ padding: "2rem" }}>
        <ContainerContentFixed style={{ maxWidth: maxWidth }}>
          <HeadingSection style={{ textAlign: "center" }}>
            Our Partners
          </HeadingSection>
          <p>pdView has long term contracts with our partners who provide the breadth and depth of talent and support required to meet our client's needs. 
          </p><p>
          pdView’s mathematical partner is Endgame-Economics. They provide many of the algorithms consumed by pdView's web services including artificial intelligence model training and battery optimisation problem formulation. Endgame Economics is the leader in providing mathematical modelling solutions and economic advice in the energy sector.
          </p><p>
          pdView’s web service partner is Pipelabs. They provide integrated technology solutions that are durable and scalable which enables pdView to grow with the complexity of the energy market.
          </p><p>
          pdView’s partners are strictly contracted on time and materials.
          </p>
          <SliderPartners />
        </ContainerContentFixed>
      </ContainerBackgroundWhite>
      <ContainerBackground style={{ padding: "2rem", backgroundColor: Colors.LIGHT_GRAY }}>
        <ContainerContentFixed style={{ maxWidth: maxWidth }}>
          <HeadingSection style={{ textAlign: "center" }}>
            Our Foundation
          </HeadingSection>
          <p>
            Ethical standards are the foundations of pdView. Our services are
            availed by our client's decision making because our skills and
            knowledge are trusted. Our mission is to build scalable and durable
            systems with beautiful and responsive user interfaces.
          </p>
          <p>
            Our vision is to complement or automate the decision making process within energy
            markets where a data rich environment
            enables a high level of abstraction and problem
            formulation. The lack of visibility and bench-marking in current
            business practices provides an opportunity to extract value by
            improving decision quality and increasing decision frequency whilst
            measuring the value of these decisions. pdView was founded in 2014.
          </p>
        </ContainerContentFixed>
      </ContainerBackground>

      <ContainerBackgroundWhite
        style={{ padding: "2rem"}}
      >
        <ContainerContentFixed style={{ maxWidth: maxWidth }}>
          <HeadingSection style={{ textAlign: "center" }}>
            Our Technology
          </HeadingSection>
          <p>
            Our technology stack is deployed in AWS and includes serverless,
            postgres, react and python. Our development approach applies weekly
            sprints, consistent pipelines, continuous deployment, code reviews,
            UML, wireframes and whiteboards.
          </p>
          <p>
            pdView is always hiring. Please contact us to learn more about the
            benefits and opportunities.
          </p>
          <SliderTechStack />
        </ContainerContentFixed>
      </ContainerBackgroundWhite>

      <ContainerBackground
        style={{ padding: "2rem", textAlign: "center",backgroundColor: Colors.LIGHT_GRAY }}
      >
        <ContainerContentFixed style={{ maxWidth: maxWidth }}>
          <HeadingSection>Our Clients</HeadingSection>
          <SliderClients rows={4} slidesPerRow={4} />
        </ContainerContentFixed>
      </ContainerBackground>


    </Layout>
  );
};

export default About;
