import React from "react";
import { object } from "prop-types";

import Pipelabs from '../../images/partners/pipelabs.svg'
import EndGame from '../../images/partners/endgame.svg'

export const SliderPartners = ({ style }) => {
    const logoStyles = {
        display: 'inline-block',
        margin: '16px 32px',
        height: '120px',
        width: 'auto'
    };

    return (
        <div style={{ textAlign: "center", ...style}}>
            <a href="https://www.endgame-economics.com/" title="Endgame Economics">
                <EndGame style={{ ...logoStyles, maxWidth: '150px '}} />
            </a>
            <a href="https://pipelabs.com.au/">
                <Pipelabs style={{ ...logoStyles, maxWidth: '150px '}} title="Pipelabs" />
            </a>
        </div>
    )
}

SliderPartners.propTypes = {
    style: object,
}